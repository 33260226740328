import { Container, Row, Col, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { allCustomerWalletAction, allCustomerWalletCardCountAction } from "../../../../store/actions/walletActions";
import WalletList from "./walletList";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

function WalletCard(props) {
  const { t } = useTranslation();
  // console.log(props)
  useEffect(() => {
    props.getWalletCardCountApi(1, 10000000);
  }, []);

  const allWalletData = props.walletCardCountRes && props.walletCardCountRes.customers;
  // console.log("allWalletData", allWalletData)
  return (
    <Container fluid className="pb-4">
      <PureBreadcrumbs />
      <br />
      <div className="row">
        <div className="col-lg-3 col-sm-12">
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("CUSTOMERSCARD")}</Card.Text>
              <Card.Title>
                <b>{props.walletCardCountRes?.total_customer}</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>
                0.0% {t(" change in last 30 days")}
              </span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/customer/totalcustomer.svg"
                    }
                    alt="customer"
                  />
                </div>
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-rules cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "blue",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("CUSTOMERSCARD")}</p>
            </div>
            <Card.Title>
              <b>{props.walletCardCountRes?.total_customer}</b>
            </Card.Title>
          </Card>
          <br />
        </div>
        <div className="col-lg-3 col-sm-12">
          <Card className="customercard card-custom total-active-rules cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "orange",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Wallet credit")}</p>
            </div>
            <Card.Title>
              <b>{props.walletCardCountRes?.total_credit}</b>
            </Card.Title>
          </Card>
          <br />
        </div>
        <div className="col-lg-3 col-sm-12">
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("DEBIT")}</Card.Text>
              <Card.Title>
                <b>{props.walletCardCountRes?.total_debit}</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>0.0% {t(" change in last 30 days")}</span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "20%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/customer/walletdebit.svg"} alt="customer" />
                </div>
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-inactive cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "red",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Debit")}</p>
            </div>
            <Card.Title>
              <b>{props.walletCardCountRes?.total_debit}</b>
            </Card.Title>
          </Card>
          <br />
        </div>
        <div className="col-lg-3 col-sm-12">
          {/* <Card className="customercard">
            <div style={{ textAlign: "left" }}>
              <Card.Text style={{ fontSize: "14px" }}>{t("AVAILABLE")}</Card.Text>
              <Card.Title>
                <b>{props.walletCardCountRes?.total_available}</b>
              </Card.Title>
              <span style={{ fontSize: "11px" }}>0.0% {t(" change in last 30 days")}</span>
              <span
                style={{
                  float: "right",
                  marginTop: "-60px",
                  marginRight: "-8px",
                }}
              >
                <div style={{ width: "50%" }}>
                  <img src={process.env.PUBLIC_URL + "/images/customer/walletavailable.svg"} alt="customer" />
                </div>
              </span>
            </div>
          </Card> */}
          <Card className="customercard card-custom total-draft cards">
            <div className="diagonal-line"></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  backgroundColor: "grey",
                  padding: "3px",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  marginRight: "8px",
                }}
              ></p>
              <p>{t("Available")}</p>
            </div>
            <Card.Title>
              <b>{props.walletCardCountRes?.total_available}</b>
            </Card.Title>
          </Card>
        </div>
      </div>

      <div className="row pad_t1">
        <div className="col-lg-12 col-sm-12">
          <Card className="customercard">
            <WalletList {...props} allWalletData={allWalletData} />
          </Card>
        </div>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.wallet.isLoading,
    walletCardCountRes: state.wallet.fetchWalletCardCount,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getWalletCardCountApi: (currentPage, pageSize) => dispatch(allCustomerWalletCardCountAction(currentPage, pageSize)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WalletCard);
