import React from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useState, useEffect } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { connect } from 'react-redux';
import './ticketManagement.css';
import { createNewTicket } from '../../../store/actions/ticketAction';
import PureBreadcrumbs from "../breadcrums";
import { allTicketAssignToUser, allUserAction } from '../../../store/actions/accessAction';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select'
import DarkThemeStyles from '../resuableComponent/reactSelectSingleValueStyle';

function CreateTicket(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { token, access, userid, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageValidation, setImageValidation] = useState(true)
  const [redirect, setRedirect] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);

  useEffect(() => {
    // props.getrole();
    props.getUsers();
    props.getAssignToUsers();
  }, [])

  const AdminUserData = props.allAssignToUser?.users
  const userdata = props.userlist.users && props.userlist.users.filter(en => en.role != "Admin")

  const ticketSchema = Yup.object().shape({
    title: Yup.string().required('Title is required').max(60, 'Title must be at most 60 characters'),
    affected_module: Yup.string().required(' Affected module is Required'),
    ticket_type: Yup.string().required('Issue type is Required'),
    severity: Yup.string().required('Please select severity'),
    description: Yup.string().required('Description is Required'),
    createdby: Yup.string(),
    updatedby: Yup.string(),
    assignedto: Yup.string().required('Assign to is Required'),
    attachments: Yup.mixed().test('fileType', 'This attachments is requird. Only image files are allowed', (value) => {
      if (!value) {
        setImageValidation(false);
        return true; // If no file is selected, the validation passes (assuming file selection is optional).
      }

      // Check if the file is an image
      const file = value;
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml']; // Add other image MIME types as needed.

      // Return true if the file is an allowed image type
      if (allowedTypes.includes(file[0] && file[0].type)) {
        // console.log("included", true);
        setImageValidation(false);
        return true;
      }
      setImageValidation(true);
      // If the file type is not allowed, the validation fails and the warning is triggered
      return false;
    }),
  });

  const { register, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({ resolver: yupResolver(ticketSchema), mode: "all" });

  // console.log(props.save)


  const onReset = () => {
    window.location.reload();
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // console.log(file);

    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();
      // console.log(reader)
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setImagePreviewUrl('');
    }
  };
  // console.log("imageValidation", imageValidation);

  const onSubmit = async (data) => {
    const formData = new FormData();

    formData.append('affected_module', data.affected_module);
    formData.append('ticket_type', data.ticket_type);
    formData.append('severity', data.severity);
    formData.append('description', data.description);
    formData.append('createdby', userid);
    formData.append('assignedto', data.assignedto);
    formData.append('title', data.title);
    formData.append('role', role);
    formData.append('operator', organisation);
    if (data.attachments) {
      formData.append('attachments', data.attachments[0]);
    }

    // console.log('affected_module:', formData.get('affected_module'));
    // console.log('ticket_type:', formData.get('ticket_type'));
    // console.log('severity:', formData.get('severity'));
    // console.log('description:', formData.get('description'));
    // console.log('createdby:', formData.get('createdby'));
    // console.log('assignedto:', formData.get('assignedto'));
    // console.log('title:', formData.get('title'));
    // console.log('role:', formData.get('role'));
    // console.log('operator:', formData.get('operator'));

    // if (data.attachments) {
    //   console.log('attachments:', formData.get('attachments'));
    // }
    // console.log("formData", formData);
    props.create(formData)
  };

  useEffect(() => {
    if (props.save.statuscode === 201) {
      setIsToastVisible(true);
      toast.success('New Ticket has been created.', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          setIsToastVisible(false);
          setRedirect(true)
        },
      });
    }
  }, [props.save]);

  if (redirect) {
    return <Redirect to='/dashboard/view_ticket' />;
  }

  const handleAffectedModule = (e) => {
    const code = e.value;
    // console.log("affected_module", code);
    setValue("affected_module", code);
  };

  const moduleOptions = [
    ...(access ? access.map(item => ({
      value: item.value,
      label: item.value
    })) : []),
    { value: "AUTHENTICATION", label: "AUTHENTICATION" },
    { value: "SETTINGS", label: "SETTINGS" },
    { value: "OTHERS", label: "OTHERS" }
  ];


  const handleAssignTo = (e) => {
    const code = e.value;
    // console.log("assignedto", code);
    setValue("assignedto", code);
  };

  const assignedtoAdminOptions = AdminUserData && AdminUserData?.map(item => ({
    value: item.userid,
    label: item.userid
  }));
  const assignedtoWithoutAdminOptions = userdata && userdata?.map(item => ({
    value: item.userid,
    label: `${item.userid} ${item.role}`
  }));


  return (
    <Container fluid className="pb-4">
      {isToastVisible && <div className="overlay" />}
      <ToastContainer
        position="top-center"
        autoClose={20}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ top: "10%", width: "auto", zIndex: "9999" }}
      />
      <PureBreadcrumbs />
      <Row>
        <Col md={10} lg={12}>
          <Card className='mt-5 tariffcard'>
            <Card.Body>
              <h2 className='mb-5'>{t("New Ticket")}</h2>
              <form autoComplete="off" onSubmit={e => e.preventDefault()} >
                <div className="form-floating">
                  <label htmlFor="title">{t("Title")} <span className='mandatory-field'>*</span></label>
                  <input autoFocus type="text" className={`form-control  gray-border ${errors.title ? 'is-invalid' : ''}`} title="title" id="floatingInputGridtitle" placeholder={t("Title")} {...register("title")} />
                  <div className="invalid-feedback">{errors.title?.message}</div>
                </div>
                <br />

                <div className="form-group">
                  <label htmlFor="affected_module">{t("Affected Module")} <span className='mandatory-field'>*</span></label>
                  <div className="form-floating">
                    <Select
                      options={moduleOptions ? moduleOptions : []}
                      className={`${errors.affected_module ? 'is-invalid' : ''}`}
                      name="affected_module"
                      id="floatingInputGridaffected_module"
                      placeholder="Select Affected Module"
                      styles={DarkThemeStyles}
                      menuPortalTarget={document.body}
                      onChange={handleAffectedModule}
                    />
                    <div className="invalid-feedback">{errors.affected_module?.message}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="ticket_type">{t("Issue Type")}  <span className='mandatory-field'>*</span></label>
                  <div className="form-floating">
                    <select className={`form-select form-control ${errors.ticket_type ? 'is-invalid' : ''}`} name="ticket_type" id="floatingSelectGridIssueType" {...register("ticket_type")}>
                      <option value="">{t("Select issue type")}</option>
                      <option value="Bug">{t("Bug")}</option>
                      <option value="Comment">{t("Comment")}</option>
                      <option value="Feature Request">{t("Feature Request")}</option>
                    </select>
                    <div className="invalid-feedback">{errors.ticket_type?.message}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label>{t("Severity")}  <span className='mandatory-field'>*</span></label>
                  <br />
                  <Col md={10} className="btn-group btn-group-lg btn-group-toggle p-2" data-toggle="buttons">
                    <label className={`btn radio-btn  p-3 pr-3 btn-light ${errors.severity ? 'is-invalid' : ''}`}>
                      <input
                        type="radio"
                        name="severity"
                        value="Low"
                        id="severityLow"
                        {...register('severity')}
                      />
                      <span className="bulletLow"></span>
                      {t("Low")}
                    </label>

                    <label className={`btn radio-btn  p-3  pr-3  btn-light ${errors.severity ? 'is-invalid' : ''}`}>
                      <input
                        type="radio"
                        name="severity"
                        value="Medium"
                        id="severityNormal"
                        {...register('severity')}
                      />
                      <span className="bulletMedium"></span>
                      {t("Medium")}
                    </label>
                    <label className={`btn radio-btn  p-3  pr-3  btn-light ${errors.severity ? 'is-invalid' : ''}`}>
                      <input
                        type="radio"
                        name="severity"
                        value="High"
                        id="severityHigh"
                        {...register('severity')}
                      />
                      <span className="bulletHigh"></span>
                      {t("High")}
                    </label>
                    <label className={`btn  radio-btn p-3  pr-3  btn-light ${errors.severity ? 'is-invalid' : ''}`}>
                      <input
                        type="radio"
                        name="severity"
                        value="Critical"
                        id="severityCritical"
                        {...register('severity')}
                      />
                      <span className="bulletCritical"></span>
                      {t("Critical")}
                    </label>
                  </Col>
                  {errors.severity && <div className="invalid-feedback">{errors.severity.message}</div>}
                </div>

                <div className="form-group">
                  <label htmlFor="status">{t("Assign to")}  <span className='mandatory-field'>*</span></label>
                  <div className="form-floating">
                    <Select
                      options={assignedtoAdminOptions ? assignedtoAdminOptions : []}
                      className={`${errors.assignedto ? 'is-invalid' : ''}`}
                      name="assignedto"
                      id="floatingInputGridassignedto"
                      placeholder="Assign To"
                      styles={DarkThemeStyles}
                      menuPortalTarget={document.body}
                      onChange={handleAssignTo}
                    />
                    <div div className="invalid-feedback">{errors.assignedto?.message}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="attachments">{t("Attachment (PNG or JPG)")} <span className='mandatory-field'>*</span></label>
                  <div className="custom-file" style={{ zIndex: "1" }}>
                    <input
                      type="file"
                      accept="image/*"
                      className={`custom-file-input ${errors.attachments ? 'is-invalid' : ''}`}
                      name="attachments"
                      id="attachments"
                      {...register("attachments")}
                      onChange={(e) => handleFileChange(e)}
                    />
                    <label className="custom-file-label" htmlFor="attachments">
                      {selectedFile ? `You have selected 1 file: ${selectedFile.name}` : 'Choose file...'}
                    </label>
                    <div className="invalid-feedback">{errors.attachments?.message}</div>
                    {/* {imagePreviewUrl && (
                      <div>
                        <p>{t("Image Preview")}:</p>
                        <img className='imagePreview' src={imagePreviewUrl} alt="Preview" style={{ height: '180px', width: '15%' }} />
                      </div>
                    )} */}
                  </div>
                </div>

                <div className='row g-2 mt-3'>
                  <div className='col-md'>
                    <div className="form-floating">
                      <label htmlFor='floatingTextarea' >{t("Description")}<span className='mandatory-field'>*</span></label>
                      <textarea className={`form-control  ${errors.description ? 'is-invalid' : ''}`} placeholder={t("Description")} aria-label={t("Description")} name="description" id="floatingTextarea" style={{ "height": "100px" }} {...register("description")}></textarea>
                      <div className="invalid-feedback">{errors.description?.message}</div>
                    </div>
                  </div>
                </div>

                <Row className='mt-4 mb-3'>
                    <Col>
                      <button className="lgn-btn btn" type="button" onClick={() => onReset()}> {t("Reset")} </button>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                   <button className="lgn-btn btn" type="submit" onClick={handleSubmit(onSubmit)}> {t("Submit")}</button>
                    </Col>
                  </Row>
              </form>
            </Card.Body>
          </Card>

        </Col>
      </Row>
    </Container >

  )
};
const mapStateToProps = (state) => {
  return {
    loading: state.ticket.isLoading,
    save: state.ticket.save_ticket,
    userlist: state.access.alluser,
    allAssignToUser: state.access.allAssignToUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    create: (formData) => dispatch(createNewTicket(formData)),
    getUsers: () => dispatch(allUserAction()),
    getAssignToUsers: () => dispatch(allTicketAssignToUser()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateTicket);
