import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { fetchChargingStatusAction, fetchEnergyAndRevenueGraph, fetchMonthSessionTypeAction } from "../../../../store/actions/insightAction";
import { Container, Card } from "react-bootstrap";
import PureBreadcrumbs from "../../breadcrums";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomMonthlyRevenue from "./monthRevenueGraph";
import CustomStationRevenue from "./stationRevenueGraph";
import RevenueHeatMap from "./heatmap";
import { t } from "i18next";

function RevenueInsightCard(props) {
    const currency = process.env.REACT_APP_CURRENCY;

    const initialStartDate = new Date(Date.now() - 3600 * 1000 * 3600); // 3600 hours ago
    const initialEndDate = new Date(Date.now() + 3600 * 1000 * 24); // 24 hours from now

    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);

    const formattedStartDate = useMemo(() => startDate.toISOString().substr(0, 10), [startDate]);
    const formattedEndDate = useMemo(() => endDate.toISOString().substr(0, 10), [endDate]);

    const loadNewData = () => {
        props.getMonthsData(formattedStartDate, formattedEndDate);
        props.getDateRangeRevenue(formattedStartDate, formattedEndDate);
    };

    useEffect(() => {
        props.getMonthsData(formattedStartDate, formattedEndDate);
        props.getDateRangeRevenue(formattedStartDate, formattedEndDate);
        props.getTotalCharging();
    }, []);

    const cardData = props.total_sesion && props.total_sesion;

    return (
        <Container fluid className="pb-4">
            <PureBreadcrumbs />
            <br />

            <div className="row">
                <div className="col-lg-4 col-sm-12">
                    <Card className="customercard">
                        <div style={{ textAlign: "center" }}>
                            <Card.Text style={{ fontSize: "30px", fontWeight: 900 }}>
                                {currency} {cardData.total_revenue}
                            </Card.Text>
                            <Card.Title style={{ fontSize: "13px" }}>
                                {t("LIFETIME    TOTAL REVENUE")}
                            </Card.Title>
                        </div>
                    </Card>
                    <br />
                </div>
                <div className="col-lg-4 col-sm-12">
                    <Card className="customercard">
                        <div style={{ textAlign: "center" }}>
                            <Card.Text style={{ fontSize: "30px", fontWeight: 900 }}>
                                {currency} {cardData.average_revenue}
                            </Card.Text>
                            <Card.Title style={{ fontSize: "13px" }}>
                                {t("LIFETIME    AVERAGE REVENUE")}
                            </Card.Title>
                        </div>
                    </Card>
                    <br />
                </div>
                <div className="col-lg-4 col-sm-12">
                    <Card className="customercard">
                        <div style={{ textAlign: "center" }}>
                            <Card.Text style={{ fontSize: "30px", fontWeight: 900 }}>
                                {currency} {cardData.today_revenue}
                            </Card.Text>
                            <Card.Title style={{ fontSize: "13px" }}>
                                {t("TODAY'S REVENUE")}
                            </Card.Title>
                        </div>
                    </Card>
                    <br />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 col-sm-12">
                    <Card className="customercard">
                        <div style={{ textAlign: "right" }}>
                            <div className="row justify-content-end">
                                <div className="col-auto claender">
                                    <DatePicker
                                        className="gray-border"
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        selectsStart
                                        startDate={startDate}
                                        dateFormat="dd/MM/yyyy"
                                        maxDate={new Date()}
                                    />
                                </div>
                                <span style={{ paddingTop: "7px" }}> To </span>
                                <div className="col-auto mx-2">

                                    <DatePicker
                                        className="gray-border"
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate}
                                        showDisabledMonthNavigation
                                        dateFormat="dd/MM/yyyy"
                                        maxDate={new Date()}
                                    />
                                </div>
                                <div className="csvbutton ">
                                    <button className="btn btn-sm" onClick={loadNewData}>
                                        <span
                                            style={{
                                                fontSize: "16px",
                                                color: "#676767",
                                                fontWeight: "600",
                                            }}
                                        >
                                            GO
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-6 col-sm-12">
                                <CustomMonthlyRevenue {...props} />
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <CustomStationRevenue {...props} />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <div className="statuscard mt-3">
                <Card.Header>{t("Revenue Heat Map")} </Card.Header>
                <RevenueHeatMap />
            </div>
        </Container>
    )

}

const mapStateToProps = (state) => {
    return {
        loading: state.insight.isLoading,
        month_total: state.insight.month_session,
        date_range_revenue: state.insight.energy_revenue_insight,
        total_sesion: state.insight.charging_total_status,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getMonthsData: (start, end) => dispatch(fetchMonthSessionTypeAction(start, end)),
        getDateRangeRevenue: (start, end) => dispatch(fetchEnergyAndRevenueGraph(start, end)),
        getTotalCharging: () => dispatch(fetchChargingStatusAction()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RevenueInsightCard);